<template>
  <lv-modal
    :opened="opened"
    size="no-padding"
    :closable="closable"
    white-rounded-close-button
    @close-modal="closeModal"
  >
    <template #content>
      <template v-if="startPopup">
        <picture>
          <source :media="`(min-width: ${$styleVariables.breakpointMd})`" :srcset="startPopup.desktopImage">
          <img
            :src="startPopup.mobileImage"
            :class="$style.popupImage"
            :aria-label="startPopup.alt"
            :alt="startPopup.alt"
            loading="lazy"
            @click="navigate"
          >
        </picture>
      </template>
    </template>
  </lv-modal>
</template>

<script>
  import { mapState } from 'pinia';
  import { LvModal } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { M_START_POPUP } from '~~/common/config/Modal.js';

  import { useModalStore } from '~~/common/stores/modal';
  import { useConfigStore } from '~~/common/stores/config';

  export default {
    name: 'AwStartupPopup',
    components: {
      LvModal,
    },
    computed: {
      ...mapState(useModalStore, {
        modal: state => state.activeGenericModal,
      }),
      ...mapState(useConfigStore, {
        startPopup: state => state.configCommon?.startPopup,
      }),
      opened () {
        return this.modal.type === M_START_POPUP;
      },
      closable () {
        return this.modal.item.closable;
      },
    },
    created () {
      if (import.meta.client && this.opened) {
        window.addEventListener('click', this.outsideClickHandler);
      }
    },
    methods: {
      closeModal () {
        const modalStore = useModalStore();
        modalStore.closeStartupPopup();

        // Don't show the popup again until cookie expiration
        this.$cookies.set('startup_popup_closed', true, {
          path: '/',
          maxAge: 7 * 24 * 60 * 60,
        });

        this.removeOutsideClickHandler();
      },
      outsideClickHandler (event) {
        if (event.target.className.includes('modalWrapper')) {
          this.closeModal();
        }
      },
      removeOutsideClickHandler () {
        window.removeEventListener('click', this.outsideClickHandler);
      },
      navigate () {
        window.location = this.startPopup.url;

        this.closeModal();
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.popupImage {
  width: 100%;
  height: auto;
  cursor: pointer;
  border-radius: inherit;
  object-fit: cover;
}
</style>
